import React, {Component} from 'react';
import './App.css';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Index from './views';
import AppNavbar from "./components/AppNavbar";
import AppFooter from "./components/AppFooter";
import AppGallery from "./views/AppGallery";


class App extends Component {
    render() {

        document.addEventListener('contextmenu', event => event.preventDefault());

        return (
            <Router>
                <AppNavbar/>
                <Switch>
                    <Route exact path={["/", "/index"]} component={Index}/>
                    <Route exact path={'/galerie'} component={AppGallery}/>
                    <Route component={Index}/>
                </Switch>
                <AppFooter/>
            </Router>
        );
    }
}

export default App;
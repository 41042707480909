import React from 'react';
import axios from 'axios';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {Loader} from "semantic-ui-react";

const API_URI = '/';

class AppGallery extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            imgCategory: [],
            photoIndex: 0,
            open: false,
            mainSrc: '',
            chargement: true
        }

    }

    componentDidMount() {
        axios('/images')
            .then(data => this.setState({imgCategory: data.data.imgCategory}))


        setTimeout(function () {
            const script = document.createElement("script");
            script.src = "/assets/js/-Filterable-Gallery-with-Lightbox-BS4-.js";
            script.async = true;
            document.body.appendChild(script);

        }, 1500)

        this.timer = setInterval(
            () => this.setState({chargement: false})
            , 1500);
    }


    componentWillUnmount() {
        clearInterval(this.timer);
    }


    render() {
        const {imgCategory} = this.state;
        if (typeof imgCategory === 'undefined' || imgCategory.length === 0) {
            return null;
        }

        return (
            <>
                <main className="page projects-page">
                    <section className="portfolio-block projects-cards" style={{padding: '0px'}}>
                        <div className="container">
                            <div className="heading"/>
                            <section className="py-5">
                                <div className="container">
                                    <h1 className="text-center">Galerie</h1>
                                    <hr className="modern-form__hr" style={{
                                        color: 'rgb(220,53,69)',
                                        marginBottom: '50px',
                                        backgroundColor: 'rgb(220,53,69)'
                                    }}/>
                                    <div className="filtr-controls">
                                    <span
                                        className="active"
                                        data-filter="all"
                                    >Tout</span>

                                        {imgCategory.map((category, index) =>
                                            <span key={category.category}
                                                  data-filter={index + 1}>{category.category}</span>
                                        )}

                                    </div>
                                    {this.state.chargement ?
                                        <div style={{marginBottom: "400px", marginTop: "400px"}}>
                                            <Loader
                                                size='medium'
                                                active={this.state.chargement}
                                                style={{marginBottom: 200}}
                                            >Chargement</Loader>
                                        </div>

                                        :
                                        <div className="row filtr-container">
                                            {imgCategory.map((category, index) =>
                                                category.images.map(image =>
                                                    <div
                                                        key={image}
                                                        className="col-sm-6 col-md-4 col-lg-3 filtr-item"
                                                        data-category={index + 1}
                                                    >
                                                        <img
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => this.setState({
                                                                open: true,
                                                                mainSrc: 'assets/img/images/' + category.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '/' + image
                                                            })}
                                                            className="img-fluid"
                                                            data-caption="<strong>Image description</strong><br><em>Lorem ipsum</em>"
                                                            src={'assets/img/images/' + category.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '/' + image}
                                                            srcSet={'assets/img/images/' + category.category.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '/' + image + " 264w"}
                                                            alt={image}
                                                        />

                                                    </div>
                                                )
                                            )}
                                        </div>


                                    }
                                </div>
                            </section>
                        </div>
                    </section>
                </main>
                {this.state.open && (
                    <Lightbox
                        mainSrc={this.state.mainSrc}
                        onCloseRequest={() => this.setState({open: false})}
                    />
                )}


            </>
        );
    }

}

export default AppGallery;
import React from 'react';
import {
    Link as ScrollLink,
    Element,
} from 'react-scroll';
import {Link} from "react-router-dom";
import axios from 'axios';
import {Icon} from "semantic-ui-react";

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            lastname: '',
            phoneNumber: '',
            email: '',
            object: '',
            message: '',
            disable: false,
            emailSent: null
        }
    }


    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            disabled: true
        });

        const {name, lastname, object, email, message, phoneNumber} = this.state;

        if (name.length !== 0 && lastname.length !== 0 && object.length !== 0 && email.length !== 0 && message.length !== 0 && phoneNumber.length !== 0) {
            axios.post('http://localhost:3333/api/email', this.state)
                .then(res => {
                    if (res.data.success) {
                        this.setState({
                            disabled: false,
                            emailSent: true
                        });
                    } else {
                        this.setState({
                            disabled: false,
                            emailSent: false
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        disabled: false,
                        emailSent: false
                    });
                })
        }
    }


    render() {
        return (
            <main className="page lanidng-page">
                <div className="landing-threshold"
                     style={{background: 'url(assets/img/IMG_7175.jpg) center / cover no-repeat', height: '45vh'}}>
                    <div className="container-fluid"
                         style={{height: '45vh', minHeight: '45vh', backgroundColor: 'rgba(0,0,0,0.44)'}}>
                        <div className="landing-intro">
                            <h1 style={{marginBottom: '50px'}}>Alexandre Marcé Portfolio</h1>
                        </div>
                    </div>
                </div>
                <section className="portfolio-block block-intro">
                    <div className="container">
                        <div
                            className="avatar"
                            style={{background: 'url(assets/img/avatars/IMG_0583.JPG) center / contain'}}
                        />
                        <div className="about-me">
                            <p>Bienvenue sur mon site vitrine,</p>
                            <p>Je suis Alexandre Marcé. Photographe professionnel installé dans la ville de Brest, je me
                                déplace dans le Finistère et toute la région Bretagne pour shooting travailler en
                                collaboration avec des modèles de tout horizon ou encore traqué les images des paysages
                                qui font rêvez.</p>
                            <p>Passionné par la photographie, j'aime apporter ma touche personnelle à chaque
                                photographie autant en termes de cadre que de couleurs.</p>
                            <p>Bonne visite sur mon site !</p>


                            <ScrollLink
                                className="btn btn-outline-danger"
                                role="button"
                                style={{borderColor: 'rgb(222,53,69)', color: 'rgb(222,53,69)'}}
                                to={'me-contacter'}
                                spy={true}
                                smooth={true}
                                duration={1000}
                            >Me contacter</ScrollLink>
                        </div>
                    </div>
                </section>

                <Element name={'a-propos'}>
                    <div className="container text-secondary">
                        <div className="d-flex justify-content-center align-items-center content">
                            <h1 className="text-uppercase text-center"
                                style={{
                                    marginRight: 0,
                                    width: '100%',
                                    fontSize: '32px',
                                    marginBottom: '10px',
                                    marginTop: '50px',
                                    fontWeight: 700,
                                    color: 'white'
                                }}>à
                                propos</h1>
                        </div>
                        <hr
                            className="modern-form__hr"
                            style={{color: 'white', marginBottom: '50px', backgroundColor: 'rgb(220,53,69)'}}
                        />
                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-8">
                                <div className="card special-skill-item border-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Photographe</h3>
                                        <br/>
                                        <div className="card-text text-justify">
                                            <p>Je suis photographe à Brest spécialisé dans le paysage et le portrait. Ce
                                                goût pour la photo, je l’ai développé lors de mes études de monteur
                                                vidéo, ou je suis me suis intéressé au cadre et pour m'entrainer je me
                                                suis mis à faire de plus en plus de photo. Depuis, cette passion je l'ai
                                                exercé plusieurs fois en tant que métier.</p>
                                            <p>À l’écoute et professionnel, j'organise mes séances photo des séance
                                                photo en collaboration selon mes disponibilités et celle des modèles
                                                voulant travailler avec moi. Selon moi, une bonne entente et le respect
                                                de chacun sont les clés d’une séance réussie.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <img
                                    className="d-flex align-content-center"
                                    src="assets/img/avatars/IMG_0578.jpg"
                                    style={{width: 340}}
                                />
                            </div>
                        </div>
                    </div>
                </Element>
                <section className="portfolio-block cv" style={{paddingBottom: '0px'}}>
                    <div className="container">
                        <div className="work-experience group">
                            <div className="heading">
                                <h2 className="text-center" style={{marginBottom: '10px'}}>Mes experiences</h2>
                                <hr className="modern-form__hr"
                                    style={{
                                        color: 'rgb(220,53,69)',
                                        marginBottom: '50px',
                                        backgroundColor: 'rgb(220,53,69)'
                                    }}/>
                            </div>
                            <div className="item">
                                <div className="row" style={{marginTop: '-30px'}}>
                                    <div className="col-md-6">
                                        <h3>Opérateur Photographe/Vendeur</h3>
                                        <h4 className="organization"
                                            style={{backgroundColor: 'rgb(220,53,69)'}}>Puy du Fou – Vendée</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">2019</span></div>
                                </div>
                                <p className="text-muted">
                                    Amener à la rencontre du visiteur de Parc du Puy du Fou afin de réaliser des clichés
                                    de leur aventures dans le parc. En 2019 j'ai été amener à participer à l'aventure
                                    Puy du Fou en tant que photographe. Sur différent point photo dans le parc une
                                    expérience costumé ou photo leur été proposé afin les faire voyager encore plus dans
                                    le temps.
                                    À la fois photographe et vendeur puisque par la suite il s'agissait de proposer les
                                    clichés réaliser à la vente au visiteur.

                                </p>
                            </div>
                            <div className="item">
                                <div className="row">
                                    <div className="col-6">
                                        <h3>Photographe confirmé</h3>
                                        <h4 className="organization"
                                            style={{backgroundColor: 'rgb(220,53,69)'}}>Actuel Studio 3 – Oudon</h4>
                                    </div>
                                    <div className="col-md-6"><span className="period">2020</span></div>
                                </div>
                                <p className="text-muted">
                                    Dans un studio / agence de photographe de mode Situé près de Nantes à Oudon. Amener
                                    à réaliser des shootings complets de famille, groupe amis, couple, solo, amateur ou
                                    mannequin pro / semi-pro.
                                    En passant par des shootings grossesses, lingerie, naissance, etc ...
                                    En tant que photographe amener à faire des également de légère retouche, à
                                    travailler dans un studio complet et son matériel.

                                </p>
                            </div>
                        </div>
                        <div className="group"/>
                    </div>
                </section>
                <section className="portfolio-block photography" style={{paddingTop: '0px', marginTop: '-50px'}}>
                    <div className="container">
                        <div className="d-flex justify-content-center align-items-center content"
                             style={{paddingTop: '0px'}}>
                            <h1 className="text-uppercase text-center"
                                style={{
                                    marginRight: 0,
                                    width: '100%',
                                    fontSize: '32px',
                                    marginBottom: '10px',
                                    marginTop: '150px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    color: 'white'
                                }}>Galerie
                            </h1>

                        </div>
                    </div>
                    <div className="container">
                        <hr className="modern-form__hr" style={{color: 'rgb(255,110,102)', marginBottom: '20px'}}/>
                        <Link
                            style={{fontSize: 18}}
                            className={'text-danger text-decoration-none d-flex justify-content-center align-items-center content'}
                            to={'/galerie'}>Plus d'images <Icon size={"small"} name={'angle double right'}/>
                        </Link>

                        <div className="row no-gutters" style={{marginTop: 50}}>
                            <div className="col-sm-12 col-md-6 col-lg-4 item zoom-on-hover">
                                <Link to="/galerie">
                                    <img
                                        className="img-fluid d-flex image"
                                        src="assets/img/nature/IMG_7044.jpg"
                                        alt={'IMG_7044.jpg'}
                                    />
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-4 item zoom-on-hover">
                                <Link to="/galerie">
                                    <img
                                        className="img-fluid image"
                                        src="assets/img/nature/IMG_7175.jpg"
                                        alt={'IMG_7175.jpg'}
                                    />
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-4 item zoom-on-hover">
                                <Link to="/galerie">
                                    <img
                                        className="img-fluid image"
                                        src="assets/img/tech/IMG_0765.jpg"
                                        alt={'IMG_0765.jpg'}
                                    />
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-4 item zoom-on-hover">
                                <Link to="/galerie">
                                    <img
                                        className="img-fluid image"
                                        src="assets/img/tech/DSC_0059-Modifier.jpg"
                                        alt={'SC_0059-Modifier.jpg'}
                                    />
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-4 item zoom-on-hover">
                                <Link to="/galerie">
                                    <img
                                        className="img-fluid image"
                                        src="assets/img/tech/IMG_0669.jpg"
                                        alt={'IMG_0669.jpg'}
                                    />
                                </Link>
                            </div>
                            <div className="col-md-6 col-lg-4 item zoom-on-hover">
                                <Link to="/galerie">
                                    <img
                                        className="img-fluid image"
                                        src="assets/img/tech/IMG_1261.jpg"
                                        alt={'IMG_1261.jpg'}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="portfolio-block skills" style={{borderStyle: 'none'}}>
                    <div className="container">
                        <div className="heading">
                            <h2 style={{marginBottom: '10px', color: 'white'}}>Logiciels Utilisés</h2>
                            <hr className="modern-form__hr" style={{color: 'rgb(255,110,102)', marginBottom: '50px'}}/>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card special-skill-item border-0">
                                    <div className="card-header bg-transparent border-0"><img
                                        src="assets/img/1200px-Adobe_Photoshop_CC_icon.svg.png"
                                        style={{width: '70px'}}/></div>
                                    <div className="card-body" style={{paddingTop: '0px'}}>
                                        <h3 className="card-title">Photoshop</h3>
                                        <p className="card-text">Logiciel que j'utilise à la fois pour la retouche post
                                            traitement, le nettoyage de cliché ou encore le digital painting à mes
                                            heures perdues.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card special-skill-item border-0">
                                    <div className="card-header bg-transparent border-0"><img
                                        src="assets/img/1200px-Adobe_Photoshop_Lightroom_CC_logo.svg.png"
                                        style={{width: '70px'}}/></div>
                                    <div className="card-body" style={{paddingTop: '0px'}}>
                                        <h3 className="card-title">LightRoom</h3>
                                        <p className="card-text">Logiciel que j'utilise en addition de Photoshop pour la
                                            colorimétrie de mes clichés afin de donner un style personnel à mes clichés
                                            de paysages ou de portrait ...</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card special-skill-item border-0">
                                    <div className="card-header bg-transparent border-0"
                                         style={{marginBottom: '-15px'}}>
                                        <img src="assets/img/Luminar_2018.png"
                                             style={{width: '70px'}}/></div>
                                    <div className="card-body" style={{paddingTop: '0px'}}>
                                        <h3 className="card-title">Luminar</h3>
                                        <p className="card-text">Logiciel de retouche que j’utilise depuis peut en
                                            addition des logiciels Adobe, il me permet grâce à de l'intelligence
                                            artificiel d'affiner le nettoyage des photos et également apporter la
                                            dernière modification visuelle a certains clichés.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Element id={'me-contacter'} style={{marginBottom: '200px'}}>
                    <div className="container">
                        <div className="text-center" style={{marginBottom: '50px'}}>
                            <h2 className="text-uppercase" style={{fontWeight: 'bold', marginBottom: '20px'}}>Me
                                contacter</h2>
                            <hr className="modern-form__hr" style={{color: 'rgb(255,110,102)', marginBottom: '50px'}}/>
                            <div className="row">
                                <div className="col-xl-2"/>
                                <div className="col">
                                    <form style={{borderStyle: 'none'}} onSubmit={this.handleSubmit}>
                                        <div className="form-row" style={{marginBottom: '15px'}}>
                                            <div className="col col-contact">
                                                <div className="form-group modern-form__form-group--padding-r">
                                                    <input
                                                        className="form-control input input-tr"
                                                        type="text"
                                                        placeholder="Nom *"
                                                        autoComplete="on"
                                                        name='name'
                                                        value={this.state.name}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className="line-box">
                                                        <div className="line"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-contact">
                                                <div className="form-group modern-form__form-group--padding-l">
                                                    <input
                                                        className="form-control input input-tr"
                                                        type="text"
                                                        placeholder="Prénom *"
                                                        autoComplete="on"
                                                        name='lastname'
                                                        value={this.state.lastname}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className="line-box">
                                                        <div className="line"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row" style={{marginBottom: '15px'}}>
                                            <div className="col col-contact">
                                                <div className="form-group modern-form__form-group--padding-r">
                                                    <input
                                                        className="form-control input input-tr"
                                                        type="email"
                                                        placeholder="Email *"
                                                        inputMode="email"
                                                        autoComplete="on"
                                                        name='email'
                                                        value={this.state.email}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className="line-box">
                                                        <div className="line"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-contact">
                                                <div className="form-group modern-form__form-group--padding-l">
                                                    <input
                                                        className="form-control input input-tr"
                                                        type="tel"
                                                        placeholder="Téléphone *"
                                                        inputMode="tel"
                                                        autoComplete="on"
                                                        name='phoneNumber'
                                                        value={this.state.phoneNumber}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className="line-box">
                                                        <div className="line"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group modern-form__form-group--padding-l">
                                            <input
                                                className="form-control input input-tr"
                                                type="text"
                                                placeholder="Objet *"
                                                inputMode="tel"
                                                autoComplete="on"
                                                name='object'
                                                value={this.state.object}
                                                onChange={this.handleChange}
                                            />
                                            <div className="line-box">
                                                <div className="line"/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <div className="form-group modern-form__form-group--padding-t">
                                                        <textarea
                                                            className="form-control input modern-form__form-control--textarea"
                                                            placeholder="Message *"
                                                            name='message'
                                                            value={this.state.message}
                                                            onChange={this.handleChange}
                                                        />
                                                    <div className="line-box">
                                                        <div className="line"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col text-center">
                                                <button

                                                    className="btn btn-danger btn-lg text-center"
                                                    style={{fontSize: '16px'}}
                                                    type="submit"
                                                    disabled={this.state.disabled}
                                                >
                                                    Envoyer
                                                </button>
                                            </div>
                                        </div>
                                        {this.state.emailSent === true &&
                                        <p className="d-inline success-msg text-success">Email Envoyé !</p>}
                                        {this.state.emailSent === false &&
                                        <p className="d-inline err-msg text-danger">Email Non Envoyé !</p>}
                                    </form>
                                </div>
                                <div className="col-xl-2"/>
                            </div>
                        </div>
                    </div>
                </Element>
            </main>
        );
    }

}

export default Index;


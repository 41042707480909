import React from 'react';
import {Link} from "react-router-dom";
import {Link as ScrollLink, scroller} from 'react-scroll';


class AppNavbar extends React.Component {


    scrollTo(dest, time) {
        setTimeout(function () {
            scroller.scrollTo(dest, {
                duration: time,
                delay: 0,
                smooth: 'easeInOutQuart'
            })
        }, 200);
    }

    render() {
        return (
            <nav
                className="navbar navbar-dark navbar-expand-lg bg-white portfolio-navbar gradient"
                style={{background: "linear-gradient(-90deg, rgb(117,0,14), rgb(48,25,32))"}}
            >
                <div className="container">
                    <Link className="navbar-brand logo" to="/">Alexandre Marcé</Link>
                    <button
                        data-toggle="collapse"
                        className="navbar-toggler"
                        data-target="#navbarNav"><span
                        className="sr-only"
                    >Toggle navigation</span>
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarNav">
                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item" role="presentation">
                                <Link className="nav-link active" to="/">Accueil</Link>
                            </li>
                            <li className="nav-item" role="presentation">
                                <Link className="nav-link active" onClick={() => this.scrollTo("a-propos", 800)} to="/">A
                                    propos</Link>
                            </li>
                            <li className="nav-item" role="presentation">
                                <Link className="nav-link" to="/galerie">Galerie</Link>
                            </li>
                            <li className="nav-item" role="presentation">
                                <Link className="nav-link active" onClick={() => this.scrollTo("me-contacter", 1200)}
                                      to="/">Me contacter</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }


}


export default AppNavbar;
import React from 'react';
import {Link} from "react-router-dom";
import {Link as ScrollLink, animateScroll as scroll, scroller} from 'react-scroll';

class AppFooter extends React.Component {

    scrollAbout = () => <Link to='/'/>;

    render() {
        return (
            <>
                <footer className="page-footer">
                    <div className="container">
                        <div className="links">
                            <ScrollLink
                                style={{cursor: 'pointer'}}
                                to="a-propos"
                                smooth={true}
                                duration={1000}
                                onClick={() => this.scrollAbout()}
                            >A propos</ScrollLink>

                            <Link to="/galerie" style={{color: 'white'}}>Galerie</Link>
                            <ScrollLink style={{cursor: 'pointer'}} to="me-contacter" smooth={true} duration={1000}>Me
                                contacter</ScrollLink>
                        </div>
                        <div className="social-icons">
                            <a rel="noopener noreferrer" target='_blank' href="https://www.facebook.com/alexandre.marce1">
                                <i className="icon ion-social-facebook" style={{margin: 0}}/>
                            </a>
                            <a rel="noopener noreferrer" target='_blank' href="https://www.instagram.com/lens__picture">
                                <i className="icon ion-social-instagram-outline" style={{margin: 0}}/>
                            </a>
                            <a rel="noopener noreferrer" target='_blank' href="https://twitter.com/L3ns_Picture">
                                <i className="icon ion-social-twitter" style={{margin: 0}}/>
                            </a>
                        </div>
                        <p>© Copyright 2020 Alexandre Marcé - Tout Droit Reservé | Développé par Alexis Stella</p>
                    </div>

                </footer>
                <Link
                    onClick={() => scroll.scrollToTop()}
                    className="cd-top js-cd-top cd-top--fade-out cd-top--show"
                    style={{backgroundImage: 'url(assets/img/cd-top-arrow.svg)', cursor: 'pointer'}}
                    to={'#'}
                >Top</Link>
            </>
        );
    }
}

export default AppFooter